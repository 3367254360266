import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PraState {
  selectedData: any | null | undefined | any[];
  filters: {
    division: any[] | undefined;
    city: any[] | undefined;
  };
  addEditFormData: any | null | undefined | any[];
  isEdit: boolean | null | undefined;
  homeScreenData: any | null | undefined | any[];
  hierarchyId: null | undefined | number | string;
  preq_status: string | null | undefined;
  approvedByMeStatus: boolean | null | undefined;
  isUerAllowedToAdd: boolean | null | undefined;
  counterProductRelDraftList: any | null | undefined | any[];
  currentDivision: any | null | undefined;
  requestList: any[] | null | undefined;
  autocomplete: {
    company: any[] | undefined;
    division: any[] | undefined;
    status: any[] | undefined;
    data: any[] | undefined;
  };
  PraFilters: {
    city: any[] | undefined;
    link: any[] | undefined;
    status: any[] | undefined;
    state: any[] | undefined;
    district: any[] | undefined;
  };
  praFiltersData: {
    city: any[] | undefined;
    link: any[] | undefined;
    status: any[] | undefined;
    state: any[] | undefined;
    district: any[] | undefined;
  };
}

const praSlice = createSlice({
  name: "pra",
  initialState: {
    selectedData: null,
    homeScreenData: null,
    addEditFormData: null,
    counterProductRelDraftList: null,
    isEdit: false,
    hierarchyId: null,
    preq_status: null,
    approvedByMeStatus: null,
    isUerAllowedToAdd: false,
    requestList: null,
    autocomplete: {
      company: undefined,
      division: undefined,
      status: undefined,
      data: undefined,
    },
    filters: {
      division: undefined,
      city: undefined,
    },
    PraFilters: { city: undefined, link: undefined, status: undefined, state: undefined, district: undefined },
    praFiltersData: {
      city: undefined,
      link: undefined,
      status: undefined,
      state: undefined,
      district: undefined,
    },
  } as PraState,
  reducers: {
    setSelectedData(state, action: PayloadAction<any | any[] | null | undefined>): void {
      state.selectedData = action.payload;
    },

    setApprovedByMeStatus(state, action: PayloadAction<boolean | null | undefined>): void {
      state.approvedByMeStatus = action.payload;
    },

    setDivisionFilter(state, action: PayloadAction<any[] | undefined>) {
      state.filters.division = action.payload;
    },

    setCityFilter(state, action: PayloadAction<any[] | undefined>) {
      state.filters.city = action.payload;
    },

    setFormData(state, action: PayloadAction<any[] | any | null | undefined>) {
      const { keyofForm, value } = action.payload;
      state.addEditFormData[keyofForm] = value;
    },

    resetForm(state) {
      state.addEditFormData = {};
    },

    setIsEdit(state, action: PayloadAction<boolean | null | undefined>) {
      state.isEdit = action.payload;
    },

    setFullFormObject(state, action: PayloadAction<any>) {
      state.addEditFormData = action.payload;
    },

    setHomeScreenData(state, action: PayloadAction<any[] | null | undefined>) {
      state.homeScreenData = action.payload;
    },
    setHierarchyId(state, action: PayloadAction<string | number | null | undefined>) {
      state.hierarchyId = action.payload;
    },
    setPreqStatus(state, action: PayloadAction<string | null | undefined>) {
      state.preq_status = action.payload;
    },
    setIsUerAllowedToAdd(state, action: PayloadAction<boolean | null | undefined>) {
      state.isUerAllowedToAdd = action.payload;
    },
    setCounterProductRelDraftList(state, action: PayloadAction<any[] | null | undefined>) {
      state.counterProductRelDraftList = action.payload;
    },
    setCurrentDivision(state, action: PayloadAction<any>) {
      state.currentDivision = action.payload;
    },
    setAutocomplete(state, action: PayloadAction<{ key: keyof typeof state.autocomplete; value: any[] | undefined }>) {
      const { key, value } = action.payload;
      state.autocomplete[key] = value;
    },
    setPraFilters(state, action: PayloadAction<{ key: keyof typeof state.PraFilters; value: any[] | undefined }>) {
      const { key, value } = action.payload;
      state.PraFilters[key] = value;
    },
    setPraFiltersData(state, action: PayloadAction<{ key: keyof typeof state.praFiltersData; value: any[] | undefined }>) {
      const { key, value } = action.payload;
      state.praFiltersData[key] = value;
    },
    setRequestList(state, action: PayloadAction<any[] | null | undefined>) {
      state.requestList = action.payload;
    },
  },
});

export const praActions = praSlice.actions;
export { praSlice };
